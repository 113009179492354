import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

if (!baseURL) {
  console.error("API base URL is not defined. Please check your .env file.");
}

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const request = async (method, endpoint, data = null, config = {}) => {
  try {
    const response = await axiosClient.request({
      method: method,
      url: endpoint,
      data: data,
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error("There was an error!", error);
    throw error;
  }
};

export const getData = async (endpoint) => {
  return request("get", endpoint);
};

export const postData = async (endpoint, data) => {
  return request("post", endpoint, data);
};

export const updateData = async (endpoint, data) => {
  return request("put", endpoint, data);
};

export const deleteData = async (endpoint) => {
  return request("delete", endpoint);
};
