// TopBar.js
import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from "../../utils/ThemeContext";

const TopBar = ({ onMenuClick }) => {
  const theme = useTheme();
  const { toggleTheme, mode } = useThemeContext(); // Get toggleTheme and mode from context

  const title = process.env.REACT_APP_TITLE || "Default Title";

  return (
    <AppBar
      className="appbar-custom"
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Toolbar className="main-toolbar">
        <Typography
          variant="h5"
          component="div"
          sx={{ flexGrow: 1, color: "#ffffff" }}
        >
          {title}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="toggle theme"
          onClick={toggleTheme}
          sx={{ mr: 2, color: "#ffffff" }}
        >
          {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
          sx={{ color: "#ffffff" }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
