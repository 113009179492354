import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getData } from "../services/apiService";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Page = () => {
  const [page, setPage] = useState(null);
  const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
  const { pageId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const loadPage = async () => {
      try {
        // Fetch the page data
        const pageData = await getData(`pages/${pageId}`);
        setPage(pageData);

        // If there is a featured media ID, fetch the image details
        const featuredMediaId = pageData.featured_media;
        if (featuredMediaId) {
          const mediaData = await getData(`media/${featuredMediaId}`);
          setFeaturedImageUrl(mediaData.source_url);
        }
      } catch (error) {
        console.error("Failed to fetch page:", error);
      }
    };

    loadPage();
  }, [pageId]);

  const navigateToRoot = () => {
    navigate("/");
  };

  if (!page) return <div>Loading...</div>;

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Toolbar
          className="secondary-toolbar"
          onClick={navigateToRoot}
          sx={{ cursor: "pointer" }}
        >
          <IconButton edge="start" color="inherit" aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Back
          </Typography>
        </Toolbar>
      </AppBar>
      <Card
        sx={{
          margin: 0,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
        className="card-custom"
      >
        {featuredImageUrl && (
          <CardMedia
            component="img"
            image={featuredImageUrl}
            alt="Featured image"
            sx={{
              width: "100%", // Ensure the image takes up full width
              height: "auto", // Maintain the aspect ratio
            }}
          />
        )}
        <CardHeader
          className="card-header-custom"
          title={
            <Typography
              variant="h5"
              component="div"
              dangerouslySetInnerHTML={{ __html: page.title.rendered }}
              sx={{ color: theme.palette.text.primary }}
            />
          }
        />
        <CardContent
          className="card-content-custom"
          sx={{ color: theme.palette.text.secondary }}
        >
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Page;
