// theme.js
import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Montserrat", "sans-serif"].join(","),
    h1: {},
    h2: {},
    h3: {},
    h4: { fontSize: "1.5rem", fontWeight: 500 },
    h5: { fontSize: "1.25rem", fontWeight: 500 },
    h6: {
      fontSize: "0.75rem",
      fontWeight: 500,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      body: "#ffffff",
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#555555",
      backToolbar: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#dd9933",
          color: "#ffffff",
          width: "100%",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "&.main-toolbar": {
            backgroundColor: "#dd9933",
            backgroundImage:
              "url('https://bycarsten.nl/wp-content/uploads/2024/04/app-header.webp')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
          "&.secondary-toolbar": {
            backgroundColor: "#000000",
            color: "#ffffff",
            backgroundImage: "none",
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Montserrat", "sans-serif"].join(","),
    h1: {},
    h2: {},
    h3: {},
    h4: { fontSize: "1.5rem", fontWeight: 500 },
    h5: { fontSize: "1.25rem", fontWeight: 500 },
    h6: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#f48fb1",
    },
    background: {
      body: "#282828",
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#aaaaaa",
      backToolbar: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#dd9933",
          color: "#ffffff",
          width: "100%",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "&.main-toolbar": {
            backgroundColor: "#dd9933",
            backgroundImage:
              "url('https://bycarsten.nl/wp-content/uploads/2024/10/app-header-dark-jpg.webp')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
          "&.secondary-toolbar": {
            backgroundColor: "#000000",
            color: "#ffffff",
            backgroundImage: "none",
          },
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
