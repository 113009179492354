// SideBar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SearchIcon from "@mui/icons-material/Search";

import menuItems from "../../store/SideBar.json";

const iconMapping = {
  HomeIcon: <HomeIcon />,
  PersonIcon: <PersonIcon />,
  HelpOutlineIcon: <HelpOutlineIcon />,
  OndemandVideoIcon: <OndemandVideoIcon />,
  SearchIcon: <SearchIcon />,
};

const SideBar = ({ isOpen, toggleDrawer }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const appVersion = process.env.REACT_APP_VERSION || "Version not set";

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: ["100%", "280px"],
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[5],
        },
      }}
    >
      <Box
        sx={{ width: "auto", paddingTop: 2 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 2,
          }}
        >
          <IconButton
            onClick={toggleDrawer(false)}
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.id}
              onClick={() => {
                toggleDrawer(false)();
                navigate(item.path);
              }}
              sx={{
                padding: "12px 24px",
                borderRadius: "0 25px 25px 0",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon
                sx={{ minWidth: "40px", color: theme.palette.text.primary }}
              >
                {iconMapping[item.icon]}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ fontSize: "1.2rem" }}
              />
            </ListItem>
          ))}
        </List>
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            bottom: 105,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            textAlign: "center",
            color: theme.palette.text.secondary,
          }}
        >
          App Version: {appVersion}
        </Typography>
      </Box>
    </Drawer>
  );
};

export default SideBar;
