import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getData } from "../services/apiService";
import {
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";

const Post = () => {
  const [post, setPost] = useState(null);
  const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
  const { postId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const loadPost = async () => {
      try {
        // Fetch the post data
        const postData = await getData(`posts/${postId}`);
        setPost(postData);

        // If there is a featured media ID, fetch the image details
        const featuredMediaId = postData.featured_media;
        if (featuredMediaId) {
          const mediaData = await getData(`media/${featuredMediaId}`);
          setFeaturedImageUrl(mediaData.source_url);
        }
      } catch (error) {
        console.error("Failed to fetch post:", error);
      }
    };

    loadPost();
  }, [postId]);

  const navigateToRoot = () => {
    navigate("/");
  };

  if (!post) return <div>Loading...</div>;

  const authorName = post._embedded?.author?.[0]?.name || "Unknown Author";

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Toolbar
          className="secondary-toolbar"
          onClick={navigateToRoot}
          sx={{ cursor: "pointer" }}
        >
          <IconButton edge="start" color="inherit" aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Back
          </Typography>
        </Toolbar>
      </AppBar>

      <Card
        sx={{
          margin: 0,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
        className="card-custom"
      >
        {featuredImageUrl ? (
          <CardMedia
            component="img"
            image={featuredImageUrl}
            alt="Featured image"
            sx={{
              width: "100%", // Ensure the image takes up full width
              height: "auto", // Maintain the aspect ratio
            }}
          />
        ) : (
          <div></div>
        )}
        <CardHeader
          className="card-header-custom"
          title={
            <Typography
              variant="h5"
              component="div"
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              sx={{ color: theme.palette.text.primary }}
            />
          }
          subheader={
            <Typography
              variant="body2"
              component="div"
              sx={{ color: theme.palette.text.secondary }}
            >
              <span className="date">
                {new Date(post.date).toLocaleDateString()}
              </span>
            </Typography>
          }
        />
        <CardContent
          className="card-content-custom"
          sx={{ color: theme.palette.text.secondary }}
        >
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Post;
