import React, { useEffect, useState } from "react";
import { getData } from "../services/apiService";
import { Pagination, Box } from "@mui/material";

import PostCard from "../components/PostCard";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10; // Number of posts per page

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      try {
        const data = await getData("posts?per_page=50&_embed");
        setPosts(data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error);
      }
      setLoading(false);
    };

    loadPosts();
  }, []);

  // Listen for changes in currentPage and then scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Calculate total number of pages
  const pageCount = Math.ceil(posts.length / postsPerPage);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading posts!</div>;

  return (
    <div>
      {currentPosts.map((post) => (
        <PostCard key={post.id} post={post} />
      ))}
      <Box display="flex" justifyContent="center" my={2} mb={8}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          sx={{
            button: { fontSize: "1.25rem" },
            ".MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#dd9933", // Ensure this matches your color
              color: "#ffffff", // Ensuring text is white for better readability
              "&:hover": {
                backgroundColor: "#dd9933", // Also style the hover state
              },
            },
          }}
        />
      </Box>
    </div>
  );
};

export default Posts;
