// FooterBar.js
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SearchIcon from "@mui/icons-material/Search";

import NavigationConfig from "../../store/FooterBar.json";

const iconMapping = {
  HomeIcon: <HomeIcon />,
  PersonIcon: <PersonIcon />,
  HelpOutlineIcon: <HelpOutlineIcon />,
  OndemandVideoIcon: <OndemandVideoIcon />,
  SearchIcon: <SearchIcon />,
};

const FooterBar = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const StyledButtonBar = styled(BottomNavigation)(({ theme }) => ({
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 9999,
    height: "80px",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(2),
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down("sm")]: {
      height: "70px",
    },
  }));

  return (
    <StyledButtonBar
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      {NavigationConfig.map((item, index) => (
        <BottomNavigationAction
          key={index}
          label={item.label}
          icon={
            <span style={{ fontSize: "1.5em" }}>{iconMapping[item.icon]}</span>
          }
          onClick={() => {
            toggleDrawer(false)();
            navigate(item.path);
          }}
          sx={{
            paddingBottom: "0",
            color: theme.palette.text.primary,
            "& .MuiBottomNavigationAction-label": {
              fontSize: "0.75rem",
            },
            "&.Mui-selected": {
              color: "#dd9933",
            },
          }}
        />
      ))}
    </StyledButtonBar>
  );
};

export default FooterBar;
