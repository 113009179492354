import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import useDocumentTitle from "./hooks/useDocumentTitle";
import { useTheme } from "@mui/material/styles";

import TopBar from "./components/common/TopBar";
import FooterBar from "./components/common/FooterBar";
import SideBar from "./components/common/SideBar";

import Posts from "./views/Posts";
import Post from "./views/Post";
import Page from "./views/Page";
import Search from "./views/Search";
import SplashScreen from "./views/SplashScreen";
import "./App.css";

function App() {
  const title = process.env.REACT_APP_HEAD_TITLE || "Default App Title";
  useDocumentTitle(title);

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading && <SplashScreen />}
      {!loading && (
        <div
          className="App"
          style={{
            backgroundColor: theme.palette.background.body,
            color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
            minHeight: "100vh",
          }}
        >
          <TopBar onMenuClick={toggleDrawer(true)} />
          <SideBar isOpen={isOpen} toggleDrawer={toggleDrawer} />
          <FooterBar toggleDrawer={toggleDrawer} />
          <div className="content">
            <Routes>
              <Route path="/" element={<Posts />} />
              <Route path="/post/:postId" element={<Post />} />
              <Route path="/page/:pageId" element={<Page />} />
              <Route path="/search" element={<Search />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
