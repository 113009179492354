import React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const PostCard = ({ post }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handlePostClick = () => {
    navigate(`/post/${post.id}`);
  };
  const featuredImage = post._embedded?.["wp:featuredmedia"]?.[0]?.source_url;
  const postDate = new Date(post.date).toLocaleDateString();

  return (
    <Card
      className="card-postCard"
      key={post.id}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={handlePostClick}
    >
      {featuredImage && (
        <CardMedia
          component="img"
          image={featuredImage}
          alt="Featured image"
          sx={{
            width: "120px",
            height: "120px",
            objectFit: "cover",
          }}
        />
      )}
      <CardContent
        sx={{
          flex: 1,
          backgroundColor: theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          sx={{ color: theme.palette.text.primary }}
        />
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary, marginTop: "4px" }}
        >
          {postDate}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PostCard;
